<script lang="ts" setup>
import { onMounted } from "vue";
import ButtonSecondary from "~/components/ButtonSecondary.vue";
import { useCartStore } from "~/store/cart";

// Data
const config = useRuntimeConfig();

const books = computed(() => useWishlistStore().items);

if (process.client) {
    await useWishlistStore().fetchItems();
}

// Functions
const moveToCart = async (id: number) => {
    await useWishlistStore().deleteItem(id);
    await useCartStore().upsertItem(id, 25);
};

onMounted(async () => {
    // swiper element
    const swiperEl = document.querySelector("#wishlist-not-in-cart-slides");
    const btnNext = document.querySelector("#wishlist-not-in-cart-slides-btn-next");
    const btnPrev = document.querySelector("#wishlist-not-in-cart-slides-btn-prev");

    if (!swiperEl) {
        return;
    }

    // swiper parameters
    const swiperParams = {
        slidesPerView: 2,
        spaceBetween: 15,
        loop: false,
        navigation: {
            nextEl: btnNext,
            prevEl: btnPrev,
        },
        autoplay: {
            delay: 8000,
            disableOnInteraction: true,
            pauseOnMouseEnter: true,
        },
        breakpoints: {
            640: { slidesPerView: 2 },
            768: { slidesPerView: 3 },
            1024: { slidesPerView: 4 },
            1280: { slidesPerView: 6 },
        },
        on: {
            init() {
                // ...
            },
        },
    };

    // now we need to assign all parameters to Swiper element
    Object.assign(swiperEl, swiperParams);

    // and now initialize it
    swiperEl?.initialize();
});
</script>

<template>
    <div v-show="books && books.length > 0">
        <HrWithText class="mb-8">
            <h2 class="text-xl font-semibold lg:text-2xl">
                Items on your wishlist <span class="block text-xl font-medium md:inline">(not included in order)</span>
            </h2>
        </HrWithText>

        <div class="relative pb-6">
            <swiper-container
                id="wishlist-not-in-cart-slides"
                init="false"
                class="swiper-container swiper-initialized swiper-horizontal px-2 md:w-[70%] lg:w-[80%] xl:w-[87%]"
            >
                <swiper-slide
                    v-for="(product, index) in books"
                    :key="index"
                    class="relative h-[inherit] overflow-hidden"
                >
                    <NuxtLink :to="`/products/${product.slug}`">
                        <img
                            :src="product.photo"
                            :srcset="`${product.photo} 1x, ${product.photo_2x} 2x`"
                            width="128"
                            height="164"
                            :alt="'Cover Image ' + product.title"
                            class="mx-auto mb-3 w-full border border-gray-300 shadow-md shadow-gray-400"
                        />
                        <div>{{ product.title }}</div>
                    </NuxtLink>

                    <div class="h-14"><!-- Empty placeholder for button --></div>
                    <ButtonSecondary py="py-1" class="absolute bottom-1 left-2" @click="moveToCart(product.id)"
                        >Move to Cart
                    </ButtonSecondary>
                </swiper-slide>
            </swiper-container>

            <div
                class="absolute top-0 right-0 z-10 hidden h-full items-center justify-center border-l-0 bg-white p-4 shadow-xl shadow-white md:flex"
            >
                <div
                    id="wishlist-not-in-cart-slides-btn-next"
                    class="flex-none cursor-pointer rounded-full border px-5 py-3 shadow-lg hover:border-2"
                >
                    <img src="/images/fa/chevron-right-regular.svg" class="w-5" />
                </div>
            </div>
            <div
                class="absolute top-0 left-0 z-10 hidden h-full items-center justify-center border-r-0 bg-white p-4 shadow-xl shadow-white md:flex"
            >
                <div
                    id="wishlist-not-in-cart-slides-btn-prev"
                    class="flex-none cursor-pointer rounded-full border px-5 py-3 shadow-lg hover:border-2"
                >
                    <img src="/images/fa/chevron-left-regular.svg" class="w-5" />
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped></style>
