<script lang="ts" setup>
import LookInside from "~/components/product/LookInside.vue";
import H3Heading from "~/components/H3Heading.vue";
import InputTag from "~/components/form/InputTag.vue";
import type CartProduct from "~/types/CartProduct";
import { type PropType, ref } from "vue";
import { debounce } from "perfect-debounce";
import { useCartStore } from "~/store/cart";
import { useCartPrices } from "~/composables/cart"; // Properties

// Properties
const props = defineProps({
    item: {
        type: Object as PropType<CartProduct>,
        required: false,
        default: () => {
            return [];
        },
    },
});

// Data
const showLookInside: Ref<boolean> = ref(false);
const maxQty = ref(useCartStore().maxQty);
const qty = ref(props.item.qty);

const { moneyFormatter } = useCartPrices();

// Functions
const updateQty = debounce(async () => {
    if (qty.value < props.item.min_qty) {
        qty.value = props.item.min_qty;
    }
    if (qty.value > maxQty.value) {
        qty.value = maxQty.value;
    }

    await useCartStore().upsertItem(props.item.product_id, qty.value, props.item.order_type);
}, 500);

const removeItemFromCart = async () => {
    await useCartStore().deleteItem(props.item?.product_id, props.item?.order_type);
};
const moveToWishlist = async () => {
    await removeItemFromCart();
    await useWishlistStore().insertItem(props.item?.product_id);
};
</script>

<template>
    <div>
        <div v-if="item">
            <div class="flex">
                <div
                    v-if="item.slug"
                    class="relative mr-6 w-2/5 flex-none md:mr-8 lg:w-1/4 xl:w-1/6"
                    @mouseover="showLookInside = true"
                    @mouseleave="showLookInside = false"
                >
                    <NuxtLink :to="`/products/${item.slug}?orderType=${encodeURIComponent(item.order_type)}`">
                        <img
                            :src="item.photo_lg"
                            :srcset="`${item.photo_lg} 1x, ${item.photo_lg_2x} 2x`"
                            width="205"
                            height="265"
                            :alt="'Cover Image ' + item.title"
                            class="sm:3/5 w-full border border-gray-300 shadow-md shadow-gray-400 md:w-auto"
                        />
                    </NuxtLink>
                    <div class="absolute bottom-2 w-full text-center md:bottom-4">
                        <LookInside v-model:show-button="showLookInside" :product="item" />
                    </div>
                </div>
                <div class="flex-1">
                    <div class="lg:flex">
                        <NuxtLink
                            :to="`/products/${item.slug}?orderType=${encodeURIComponent(item.order_type)}`"
                            class="flex-auto"
                        >
                            <H3Heading size="flex-1 text-xl xl:text-2xl" line-height="leading-tight"
                                >{{ item.title }}
                            </H3Heading>
                        </NuxtLink>
                        <div class="mt-6 flex-none lg:mt-0 lg:w-1/3 lg:text-right">
                            <!-- Price -->
                            <div class="mt-2 mb-2 hidden text-base font-bold sm:block xl:mt-0 xl:text-xl">
                                Unit Price:
                                {{ moneyFormatter.format(item.price) }}
                            </div>
                            <div class="leading-tight sm:hidden">
                                <div class="text-sm">Unit Price</div>
                                <div class="text-2xl font-bold">{{ moneyFormatter.format(item.price) }}</div>
                            </div>

                            <!-- Bulk Pricing applied, if not digital only option -->
                            <div
                                v-if="item.order_type.toLowerCase() !== 'digital only'"
                                class="font-bold sm:text-xs xl:text-base"
                            >
                                <div v-if="qty >= 25">
                                    <NuxtImg src="/images/fa/check-regular-green.svg" class="mr-1 inline-block w-4" />
                                    Bulk Pricing Applied
                                </div>
                                <div v-else>
                                    <span class="text-abc-red">+{{ item.qty_until_discount }}</span> copies to save
                                    {{ item.discount_percent }}%
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mt-4 hidden sm:block lg:flex">
                        <!-- Includes / wishlist / Remove from cart-->
                        <div class="hidden flex-1 md:block">
                            <div class="mb-2 font-bold">Includes:</div>
                            <div class="text-sm leading-tight">
                                <div v-if="item.order_type == useCartPrices().DIGITAL_ONLY">
                                    {{ qty }} Digital Workbooks
                                </div>
                                <div v-if="item.order_type == useCartPrices().COLOR_PRINT">
                                    {{ qty }} Color Print Workbooks
                                </div>
                                <div v-else-if="item.order_type == useCartPrices().B_W_PRINT">
                                    {{ qty }} B&W Print Workbooks
                                </div>
                                <div>{{ qty }} CourseWave Codes for Online Testing</div>
                            </div>
                            <div class="mt-4 hidden lg:block">
                                <div
                                    class="text-abc-blue mr-4 inline-block cursor-pointer md:mr-8"
                                    @click="moveToWishlist"
                                >
                                    Move to Wishlist
                                </div>
                                <div class="text-abc-blue inline-block cursor-pointer" @click="removeItemFromCart">
                                    Remove<span class="hidden md:inline"> from cart</span>
                                </div>
                            </div>
                        </div>
                        <!-- Qty  -->
                        <div class="hidden flex-none pt-6 sm:block lg:w-1/3 lg:pt-0 lg:text-right">
                            <div class="mb-1 text-sm">Quantity (min {{ item.min_qty }})</div>
                            <InputTag
                                v-model="qty"
                                type="number"
                                class="w-full rounded-full sm:w-1/2 sm:text-right md:w-28 md:text-left"
                                :min="item.min_qty"
                                :max="maxQty"
                                @change="updateQty"
                            />

                            <div class="mt-4 lg:hidden">
                                <div
                                    class="text-abc-blue mr-4 inline-block cursor-pointer md:mr-8"
                                    @click="moveToWishlist"
                                >
                                    Move to Wishlist
                                </div>
                                <div class="text-abc-blue inline-block cursor-pointer" @click="removeItemFromCart">
                                    Remove<span class="hidden md:inline"> from cart</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mt-4 flex sm:hidden">
                <div class="flex-none">
                    <div class="mb-1 text-sm">Quantity (min 10)</div>
                    <InputTag v-model="qty" type="number" class="w-32 rounded-full" min="10" />
                </div>
                <div class="flex-1 pl-4">
                    <div class="text-abc-blue mt-6 mr-4 inline-block cursor-pointer" @click="moveToWishlist">
                        Move to Wishlist
                    </div>
                    <div class="text-abc-blue mt-6 inline-block cursor-pointer" @click="removeItemFromCart">Remove</div>
                </div>
            </div>
        </div>
        <div v-else>Product not found!</div>
    </div>
</template>

<style scoped></style>
