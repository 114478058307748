<script lang="ts" setup>
import ButtonPrimary from "~/components/ButtonPrimary.vue";
import { useCartPrices } from "~/composables/cart";
import { useUserStore } from "~/store/user";
import DownloadQuote from "~/components/cart/DownloadQuote.vue";

// Data
const {
    moneyFormatter,
    subtotal,
    salesTax,
    total,
    qty,
    shippingCost,
    numOfDigitalWorkbooks,
    numOfPrintedWorkbooks,
    numOfPrintedColorWorkbooks,
    numOfCwCodes,
} = useCartPrices();

const isLoggedIn = computed(() => useUserStore().isLoggedIn);
</script>

<template>
    <div>
        <H2Heading size="text-xl md:text-2xl" class="hidden md:block">Order Summary</H2Heading>
        <ul class="mb-4 hidden text-sm md:block">
            <li>{{ numOfDigitalWorkbooks }} Digital Workbooks</li>
            <li v-if="numOfPrintedWorkbooks">{{ numOfPrintedWorkbooks }} Printed Workbooks</li>
            <li v-if="numOfPrintedColorWorkbooks">{{ numOfPrintedColorWorkbooks }} Printed Color Workbooks</li>
            <li>{{ numOfCwCodes }} CourseWave Codes for Online Testing</li>
        </ul>

        <hr class="mb-1 hidden md:block" />

        <!-- NOTE: Shipping & tax do not reflect what user enter in the download quote form. -->
        <table class="w-full">
            <tbody>
                <tr>
                    <td>Subtotal</td>
                    <td class="text-right">{{ moneyFormatter.format(subtotal) }}</td>
                </tr>
                <tr>
                    <td>Shipping</td>
                    <td class="text-right">{{ moneyFormatter.format(shippingCost) }}</td>
                </tr>
                <tr>
                    <td>Sales Tax</td>
                    <td class="text-right">{{ moneyFormatter.format(salesTax) }}</td>
                </tr>
                <tr>
                    <td colspan="2">
                        <hr class="mt-1 hidden md:block" />
                    </td>
                </tr>
                <tr class="text-lg font-bold">
                    <td>Total</td>
                    <td class="text-right">{{ moneyFormatter.format(total) }}</td>
                </tr>
            </tbody>
        </table>

        <DownloadQuote />

        <ButtonPrimary
            v-if="qty > 0"
            type="link"
            :to="isLoggedIn ? '/checkout/step/1' : '/checkout'"
            class="mt-3 w-full"
        >
            Proceed to Checkout
        </ButtonPrimary>

        <div class="mt-6 text-center">
            <NuxtLink to="/account/submit-a-po" class="text-abc-blue">Need to submit a PO?</NuxtLink>
        </div>
    </div>
</template>
